import React, {useRef} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navigation from './components/Navigation';
import Home from './pages/Home';
import Prestations from './pages/Prestations';
import APropos from './pages/APropos';
import Contact from './pages/Contact';
import LegalPages from './pages/LegalPages';
import Footer from './components/Footer';
import './index.css'; 

function App() {
  const topRef = useRef(null);
  return (
    <Router>
      <div className="flex flex-col min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 text-gray-800 antialiased">
        <Helmet>
          <title>The Herzian - Musique Live pour tous vos événements</title>
          <meta 
            name="description" 
            content="The Herzian : Prestation musicale live à Paris et dans toute la France. Mariage, anniversaire, événements. Musique acoustique, country, jazz et folk."
          />
          <meta 
            name="keywords" 
            content="musique live, groupe musique paris, musiciens événements, mariage musique, anniversaire musique, jazz acoustique, groupe folk, Harry Kalfon"
          />
        </Helmet>
        
        <Navigation className="shadow-md bg-white sticky top-0 z-50 transition-shadow duration-300 hover:shadow-lg" />
        
        <main className="flex-grow container mx-auto px-4 py-8 md:px-8 lg:px-16 xl:max-w-7xl transform transition-all duration-300 ease-in-out hover:scale-[1.005]">
          <div className="bg-white rounded-2xl shadow-2xl p-6 md:p-10 lg:p-16 transform transition-all duration-500 hover:shadow-3xl hover:-translate-y-1">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/prestations" element={<Prestations />} />
              <Route path="/a-propos" element={<APropos />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/legal" element={<LegalPages topRef={topRef}  />} />
            </Routes>
          </div>
        </main>
        
        <Footer topRef={topRef}  className="bg-gray-800 text-white py-8 mt-8 shadow-inner transition-colors duration-300 hover:bg-gray-700" />
      </div>
    </Router>
  );
}

export default App;