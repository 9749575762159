import React, { useState, useEffect } from 'react';
import { performances } from '../data/performances';
import PerformanceCard from '../components/PerformanceCard';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const Home = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselImages = [
    '/images/plateforme0.webp',
    '/images/perso1.webp',
    // '/images/performance3.jpg'
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % carouselImages.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % carouselImages.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + carouselImages.length) % carouselImages.length);
  };

  return (
    <div className="space-y-16">
      <section className="hero text-center py-16 bg-gradient-to-r from-blue-50 to-indigo-50 rounded-2xl shadow-lg transform transition-all duration-300 hover:scale-[1.01]">
        <h1 className="text-5xl font-extrabold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-indigo-600 animate-gradient-x">
          The Herzian
        </h1>
        <p className="text-xl text-gray-700 font-medium max-w-2xl mx-auto px-4">
          Musique Live pour tous vos Événements à Paris et dans toute la France
        </p>
      </section>

      <section className="carousel relative group">
        <div className="relative h-[500px] overflow-hidden rounded-2xl shadow-2xl">
          {carouselImages.map((image, index) => (
            <img 
              key={index}
              src={image} 
              alt={`Performance ${index + 1}`} 
              className={`absolute inset-0 w-full h-full object-cover object-top transition-all duration-700 ease-in-out ${
                index === currentSlide 
                  ? 'opacity-100 scale-100' 
                  : 'opacity-0 scale-95'
              }`}
            />
          ))}
          
          {/* Navigation Buttons */}
          <button 
            onClick={prevSlide}
            className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/70 hover:bg-white/90 rounded-full p-2 shadow-md transition-all duration-300 opacity-0 group-hover:opacity-100 focus:outline-none"
          >
            <ChevronLeft className="text-gray-700" />
          </button>
          <button 
            onClick={nextSlide}
            className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/70 hover:bg-white/90 rounded-full p-2 shadow-md transition-all duration-300 opacity-0 group-hover:opacity-100 focus:outline-none"
          >
            <ChevronRight className="text-gray-700" />
          </button>

          {/* Slide Indicators */}
          <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
            {carouselImages.map((_, index) => (
              <span 
                key={index}
                className={`h-2 w-2 rounded-full transition-all duration-300 ${
                  index === currentSlide 
                    ? 'bg-blue-600 w-6' 
                    : 'bg-gray-300 w-2'
                }`}
              />
            ))}
          </div>
        </div>
      </section>

      <section className="performances">
        <div className="grid md:grid-cols-3 gap-8 transition-all duration-500">
          {performances.map((perf, index) => (
            <div 
              key={perf.id} 
              className="transform transition-all duration-500 hover:-translate-y-2 hover:shadow-2xl"
            >
              <PerformanceCard performance={perf} />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Home;

// Add custom CSS for gradient animation
const styles = `
@keyframes gradient-x {
  0%, 100% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
}

.animate-gradient-x {
  background-size: 200% 200%;
  animation: gradient-x 5s ease infinite;
}
`;

const styleSheet = document.createElement("style")
styleSheet.type = "text/css"
styleSheet.innerText = styles
document.head.appendChild(styleSheet)